import { Environment } from '../types/environment.interface';

export const environment: Environment = {
  apiUrl: 'https://zwydh7kcl7.execute-api.eu-central-1.amazonaws.com/',
  bucketUrl: 'https://d28eh3msz40qrb.cloudfront.net',
  covalentApiKey: 'cqt_rQtBxyG4t4WKC9Xm9wDtqBQp7VRW',
  chains: [
    {
      id: 11155111,
      label: 'Sepolia',
      rpcUrl:
        'https://eth-sepolia.g.alchemy.com/v2/ZQsJwTjKphuxEb-ZQzXTwcoQ7fgnZdxN',
      token: 'ETH',
      blockExplorerUrl: 'https://sepolia.etherscan.io',
    },
    {
      id: 80001,
      label: 'Mumbai',
      rpcUrl:
        'https://polygon-mumbai.g.alchemy.com/v2/teq3TUl-qFrYYjEeWHs1Lo18rm-LUP6E',
      token: 'MATIC',
      blockExplorerUrl: 'https://mumbai.polygonscan.com',
      icon: 'https://i.pinimg.com/originals/9b/1e/97/9b1e977d00b5d887608b156705a10759.png',
      color: '#8247e5'
    },
  ],
};
