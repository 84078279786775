import { Injectable, NgZone } from '@angular/core';
import Onboard, { OnboardAPI, WalletState } from '@web3-onboard/core';
import injectedModule from '@web3-onboard/injected-wallets';
import metamaskModule from '@web3-onboard/metamask';
import walletConnectModule from '@web3-onboard/walletconnect';
import {
  BehaviorSubject,
  Subject,
  distinctUntilChanged,
  filter,
  from,
  fromEvent,
  map,
  startWith,
  switchMap
} from 'rxjs';
import { environment } from '../../environments/environment';

import { Router } from '@angular/router';
import { BrowserProvider, JsonRpcProvider } from 'ethers';
import { enterZone } from '../../utils/enter-zone.operator';
@Injectable({
  providedIn: 'root',
})
export class Web3OnboardService {
  private static readonly PROVIDER_KEY = 'DEFACTOR_ASSETS_LAST_USED_PROVIDER';
  private static readonly wallets = [
    injectedModule(),
    metamaskModule({ options: {} }),
    walletConnectModule({
      projectId: '037f5e845b905b8845f4db6035c71705',
      dappUrl: window.location.origin,
      requiredChains: environment.chains.map((chain) => chain.id),
      qrModalOptions: {
        themeVariables: {
          '--wcm-z-index': '1100',
        },
      },
    }),
  ];

  private refreshWallet$ = new Subject<void>();

  private __web3OnboardInstance?: OnboardAPI;

  private get _web3OnboardInstance() {
    if (!this.__web3OnboardInstance) {
      throw new Error('Must call initialize first');
    }
    return this.__web3OnboardInstance;
  }

  private _wallets = new BehaviorSubject<WalletState[]>([]);

  wallets$ = this._wallets.asObservable();

  activeWallet$ = this.refreshWallet$.pipe(
    startWith(null),
    switchMap(() => this.wallets$.pipe(
      map((wallets) => wallets.at(0)?.accounts.at(0)?.address),
      filter((address): address is string => !!address),
      distinctUntilChanged(),
    ))
  )


  provider$ = this.wallets$.pipe(
    filter((wallets) => !!wallets.length),
    filter(([wallet]) => !!wallet.chains.length),
    map(
      ([wallet]) =>
        new BrowserProvider(wallet.provider, parseInt(wallet.chains.at(0)!.id))
    )
  );

  signer$ = this.provider$.pipe(switchMap((provider) => provider.getSigner()));

  constructor(private ngZone: NgZone, private router: Router) {}

  initialize() {
    if (!!this.__web3OnboardInstance) return;

    this.__web3OnboardInstance = Onboard({
      accountCenter: {
        desktop: {
          enabled: true,
          position: 'topRight',
          minimal: window.innerWidth < 1050,
          hideTransactionProtectionBtn: true,
        },
        mobile: {
          enabled: true,
          minimal: true,
          position: 'topRight',
          hideTransactionProtectionBtn: true,
        },
      },
      containerElements: {
        accountCenter: '#accountCenter',
      },
      wallets: Web3OnboardService.wallets,
      chains: environment.chains,
      appMetadata: {
        name: 'Defactor assets',
        description: 'Defactor assets',
        icon: 'assets/icons/favicon.png',
        logo: 'assets/icons/logo.svg',
        recommendedInjectedWallets: [
          { name: 'MetaMask', url: 'https://metamask.io' },
        ],
      },
    });

    this._web3OnboardInstance.state
      .select('wallets')
      .pipe(
        distinctUntilChanged((previous, current) => {
          // disconnect all wallets event
          if (!!previous.length && !current.length) {
            localStorage.removeItem(Web3OnboardService.PROVIDER_KEY);
            this.router.navigate(['/']);
          }
          return false;
        }),
        enterZone(this.ngZone)
      )
      .subscribe(this._wallets);

    fromEvent(window, 'resize')
      .pipe(map((event) => event.target as Window))
      .subscribe((window) => {
        this._web3OnboardInstance.state.actions.updateAccountCenter({
          minimal: window.innerWidth < 1050,
        });
      });

    this._initialize();
  }

  private async _initialize() {
    const autoSelectProvider = localStorage.getItem(
      Web3OnboardService.PROVIDER_KEY
    );

    const wallets = await this._web3OnboardInstance.connectWallet(
      autoSelectProvider
        ? {
            autoSelect: { label: autoSelectProvider, disableModals: false },
          }
        : undefined
    );

    const selectedProvider = wallets.at(0)?.label;
    if (selectedProvider) {
      localStorage.setItem(Web3OnboardService.PROVIDER_KEY, selectedProvider);
    }

    const wallet = wallets[0];
    this._web3OnboardInstance.state.actions.setPrimaryWallet(
      wallet,
      '0x8db2FefE7e93821C1cCf2476D42AE1B11b209c09'
    );
  }

  async connect() {
    await this._web3OnboardInstance.connectWallet();
  }

  get state() {
    return this._web3OnboardInstance.state.get();
  }

  setChain(chainId: number) {
    return from(this._web3OnboardInstance.setChain({ chainId })).pipe(
      filter((success) => success)
    );
  }

  refreshWallet() {
    this.refreshWallet$.next();
  }

  getProviderForChain(chainId: number) {
    const chainData = environment.chains.find(chain => chain.id === chainId);
    if (!chainData) {
      throw new Error(`Chain data for chainId ${chainId} not found`);
    }

    return new JsonRpcProvider(chainData.rpcUrl, chainData.id);
  }
}
